import { reactive } from 'vue';
import { removeFirst } from "razomy.js/list/remove_first";
const notation_state = reactive({
    notation: null,
    listeners: []
});
const set = (notation) => {
    notation_state.notation = notation;
    notation_state.listeners.forEach(i => i());
};
const get = (key) => {
    return notation_state.notation[key];
};
const load = async () => {
    return await fetch(notation_store.ctx.url + `/api/get?=${notation_store.ctx.id}`, { method: 'GET' })
        .then(i => i.json())
        .then(data => set(data));
};
const start = async () => {
    notation_store.ctx.notation.socket.on(notation_store.ctx.id, set);
    await load();
};
const finish = async () => {
    notation_store.ctx.notation.socket.off(notation_store.ctx.id, set);
};
function on(key, cb) {
    notation_state.listeners.push(cb);
}
function off(key, cb) {
    removeFirst(notation_state.listeners, cb);
}
const notation_store = {
    ctx: null,
    notation_state,
    get,
    set,
    on,
    off,
    start,
    finish,
};
export default notation_store;
