function on(key, cb) {
    const web_socket = socket_store.ctx.web_socket;
    web_socket.on(key, cb);
}
function off(key, cb) {
    const web_socket = socket_store.ctx.web_socket;
    web_socket.on(key, cb);
}
const socket_store = {
    ctx: null,
    on,
    off,
};
export default socket_store;
