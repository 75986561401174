import { directive } from "razomy.js/vue/notation/directive";
import notation_store from './notation_store';
import socket from './socket';
const plugin = {
    install(app, ctx) {
        const razomy = {
            notation: {
                store: notation_store,
                socket: socket,
            },
            web_socket: ctx.web_socket,
            id: ctx.id,
            url: ctx.url
        };
        notation_store.ctx = razomy;
        socket.ctx = razomy;
        window.razomy = app.config.globalProperties.razomy = razomy;
        window.rn = razomy.notation.store.get;
        app.provide('razomy', razomy);
        app.directive('rn', directive);
    }
};
export const rnPlugin = plugin;
