<template>
  <h1 v-rn="'title'"></h1>

  <h4>Monster Match</h4>
  <a href='https://monster-match.razomy.com/'>monster-match.razomy.com</a>
  <h4>Rover Speak</h4>
  <a href='https://rover-speak.razomy.com/'>rover-speak.razomy.com</a>
  <h4>Combine Numbers</h4>
  <a href='https://play.google.com/store/apps/details?id=com.razomy.games.combine_numbers'>play.google.com/store/apps/details?id=com.razomy.games.combine_numbers</a>
  <!--  <h4>Notion Editor</h4>-->
  <!--  https://editor.notation.razomy.com-->
  <h4>Function</h4>
  <a href='https://function.razomy.com/'>function.razomy.com</a>
  <!--  <h4>Space</h4>-->
  <!--  <a href='https://space.razomy.com/'>space.razomy.com</a>-->
  <h4>Open Source</h4>
  <a href='https://github.com/razomy'>github.com/razomy</a>
</template>
<style>
h4 {
  margin-top: 1rem;
}
</style>
<script setup>
document.title = `${rn('title')}`;
</script>
