import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../pages/home_page.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/pages/about_page.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('@/pages/careers_page.vue')
  },
  {
    path: '/term-of-use',
    name: 'term-of-use',
    component: () => import('@/pages/terms_of_use_page.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/pages/legal_page.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/pages/support_page.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/pages/privacy_policy_page.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: () => import('@/pages/not_found_page.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
