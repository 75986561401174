import {createApp} from 'vue';
import App from './App.vue';

import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import '@mdi/font/css/materialdesignicons.css';
import * as vuetify_directives from 'vuetify/directives';
import router from './router';
import {rnPlugin} from 'razomy.js/vue/notation/plugin_lib';
import {create} from 'razomy.js/socket/web/create';

const ctx = {
    id: 'razomy:company:web',
    url: process.env.VUE_APP_SERVER_LOCATION_URL
}

ctx.web_socket = create(ctx);

const vuetify = createVuetify({
    components,
    directives: vuetify_directives,
});

const main = async () => {
    return createApp(App)
        .use(router)
        .use(vuetify)
        .use(rnPlugin, ctx)
        .mount('#app');
};

main().then();
