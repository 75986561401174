<template>
  <v-layout v-if="!is_loading">
    <v-main class='main'>

      <v-toolbar class="nav-bar" dense flat color="white" :elevation='0'>
        <v-img class='mx-2'
               max-height='40'
               max-width='40'
               style="height:40px;width:40px"
               aspect-ratio='1/1'
               contain
               src='./favicon.png'
        ></v-img>

        <v-btn :to="{name:'home'}" v-rn="'title'"></v-btn>
        <v-btn :to="{name:'about'}" v-rn="'about:title'"></v-btn>
        <v-btn :to="{name:'careers'}" v-rn="'careers:title'"></v-btn>
        <v-btn :to="{name:'support'}" v-rn="'support:title'"></v-btn>
        <v-btn :to="{name:'legal'}" v-rn="'legal:title'"></v-btn>
        <v-btn :to="{name:'term-of-use'}" v-rn="'terms_of_use:title'"></v-btn>
        <v-btn :to="{name:'privacy-policy'}" v-rn="'privacy_policy:title'"></v-btn>

      </v-toolbar>

      <v-container fluid class='container'>
        <router-view/>
      </v-container>
      <!--      <toggle-theme></toggle-theme>-->
    </v-main>
  </v-layout>
  <v-progress-linear v-if="is_loading"
                     color="yellow-darken-2"
                     indeterminate
  ></v-progress-linear>
</template>
<style>
.nav-bar .v-toolbar__content {
  flex-wrap: wrap;
  height: auto !important;
}

pre {
  white-space: break-spaces;
}
</style>
<script>
import {inject} from 'vue';

export default {
  metaInfo: {
    title: 'Razomy'
  },
  data() {
    return {is_loading: true}
  },
  mounted() {
    this.razomy = inject('razomy');
    this.razomy.notation.store.start().then(() => this.is_loading = false);
  },
  unmounted() {
    this.razomy.notation.store.finish();
  }
}

// import ToggleTheme from '@/components/toggleTheme.vue';</script>
